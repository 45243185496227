<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="9" class="d-flex justify-start align-center"><h2>Dashboard</h2></v-col>
      </v-row>
    </v-col>

    <v-snackbar v-model="alert.status" :timeout="timeout" :color="alert.success ? 'success' : 'error'" right bottom>
      {{ alert.message }}
    </v-snackbar>
  </v-row>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical, mdiRefresh, mdiFileOutline } from '@mdi/js'
import axios from 'axios'
import { shortlessDateTime } from '@/utils'

export default {
  setup() {
    return {
      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiRefresh,
        mdiFileOutline,
      },
    }
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem('user'))
    },
  },
  data() {
    return {
      alert: {
        status: false,
        success: false,
        message: '',
      },
      timeout: 1200,
      loadingTable: false,
      total: 0,
    }
  },
  mounted() {},
  methods: {},
}
</script>

<style>
</style>